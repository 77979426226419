import { useCallback } from 'react'
import { CheckCircleIcon, WarningIcon, WarningTwoIcon } from '@chakra-ui/icons'
import { Card, Text, useToast as useChakraToast } from '@chakra-ui/react'

const TOAST_DURATION = 3000

const STATUSES = {
  success: 'success',
  danger: 'danger',
  warning: 'warning',
}

const COLOR_VARIANTS = {
  [STATUSES.success]: 'green',
  [STATUSES.danger]: 'red',
  [STATUSES.warning]: 'orange',
}

const ICONS = {
  [STATUSES.success]: CheckCircleIcon,
  [STATUSES.danger]: WarningIcon,
  [STATUSES.warning]: WarningTwoIcon,
}

export type ToastProps = {
  message: string
  status: keyof typeof STATUSES
}

export const useToast = () => {
  const chakraToast = useChakraToast()

  const toast = useCallback(
    ({ message, status }: ToastProps) => {
      const Icon = ICONS[status]

      chakraToast({
        position: 'top',
        duration: TOAST_DURATION,
        render: () => (
          <Card p={3} flexDir="row" alignItems="center" bg={COLOR_VARIANTS[status]}>
            <Icon color="white" />
            <Text ml={2} variant="regularBold" color="white">
              {message}
            </Text>
          </Card>
        ),
      })
    },
    [chakraToast]
  )

  return toast
}
