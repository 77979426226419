import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
};

export type ApiKey = {
  __typename?: 'ApiKey';
  address: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  key: Scalars['String'];
  name: Scalars['String'];
};

export type Contract = {
  __typename?: 'Contract';
  abi: Scalars['JSON'];
  compilerVersion: Scalars['String'];
  evmVersion: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  optimizer: Optimizer;
  sources: Array<Source>;
};

export type CreateApiKeyInput = {
  name: Scalars['String'];
};

export type DeleteApiKeyInput = {
  id: Scalars['String'];
};

export type Log = {
  __typename?: 'Log';
  address: Scalars['String'];
  data: Scalars['String'];
  name: Scalars['String'];
  topics: Array<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  authenticate: Scalars['Boolean'];
  createApiKey: ApiKey;
  deleteAllApiKeys: Scalars['Boolean'];
  deleteApiKey: Scalars['Boolean'];
  updateApiKey: ApiKey;
  verifyContract: Scalars['Boolean'];
};


export type MutationAuthenticateArgs = {
  password: Scalars['String'];
};


export type MutationCreateApiKeyArgs = {
  input: CreateApiKeyInput;
};


export type MutationDeleteApiKeyArgs = {
  input: DeleteApiKeyInput;
};


export type MutationUpdateApiKeyArgs = {
  input: UpdateApiKeyInput;
};


export type MutationVerifyContractArgs = {
  input: VerifyContractInput;
};

export type Optimizer = {
  __typename?: 'Optimizer';
  enabled: Scalars['Boolean'];
  runs: Scalars['Int'];
};

export type OverviewData = {
  __typename?: 'OverviewData';
  marketCapUsd: Scalars['Float'];
  priceBtc: Scalars['Float'];
  priceChangePercentage24h: Scalars['Float'];
  priceUsd: Scalars['Float'];
};

export type PaginationContext = {
  __typename?: 'PaginationContext';
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  totalElements: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  apiKeys: Array<ApiKey>;
  overview: OverviewData;
  tokenMappings: TokenMapping;
  transactionReceipt: Transaction;
  verifiedContract?: Maybe<Contract>;
};


export type QueryTokenMappingsArgs = {
  limit?: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryTransactionReceiptArgs = {
  txnHash: Scalars['String'];
};


export type QueryVerifiedContractArgs = {
  hash: Scalars['String'];
};

export type Source = {
  __typename?: 'Source';
  content: Scalars['String'];
  name: Scalars['String'];
};

export type TokenMapping = {
  __typename?: 'TokenMapping';
  data: Array<TokenMappingData>;
  paginationContext: PaginationContext;
};

export type TokenMappingData = {
  __typename?: 'TokenMappingData';
  decimalsL1: Scalars['Float'];
  decimalsL2: Scalars['Float'];
  id: Scalars['ID'];
  l1Address: Scalars['String'];
  l2Address: Scalars['String'];
  symbol: Scalars['String'];
};

export type Transaction = {
  __typename?: 'Transaction';
  blockHash: Scalars['String'];
  blockNumber: Scalars['Int'];
  contractAddress: Scalars['String'];
  cumulativeGasUsed: Scalars['Int'];
  effectiveGasPrice: Scalars['Int'];
  events: Scalars['JSON'];
  from: Scalars['String'];
  gasUsed: Scalars['Int'];
  logs: Array<Log>;
  logsBloom: Scalars['String'];
  status: Scalars['Boolean'];
  to: Scalars['String'];
  transactionHash: Scalars['String'];
  transactionIndex: Scalars['Int'];
};

export type UpdateApiKeyInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type VerifyContractInput = {
  contractAddress: Scalars['String'];
  contractJson: Scalars['JSON'];
  version: Scalars['String'];
};

export type ApiKeysQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiKeysQuery = { __typename?: 'Query', apiKeys: Array<{ __typename?: 'ApiKey', id: string, address: string, name: string, key: string, createdAt: any }> };

export type CreateApiKeyMutationVariables = Exact<{
  input: CreateApiKeyInput;
}>;


export type CreateApiKeyMutation = { __typename?: 'Mutation', createApiKey: { __typename?: 'ApiKey', id: string, address: string, name: string, key: string, createdAt: any } };

export type UpdateApiKeyMutationVariables = Exact<{
  input: UpdateApiKeyInput;
}>;


export type UpdateApiKeyMutation = { __typename?: 'Mutation', updateApiKey: { __typename?: 'ApiKey', id: string, address: string, name: string, key: string, createdAt: any } };

export type DeleteApiKeyMutationVariables = Exact<{
  input: DeleteApiKeyInput;
}>;


export type DeleteApiKeyMutation = { __typename?: 'Mutation', deleteApiKey: boolean };

export type DeleteAllApiKeysMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteAllApiKeysMutation = { __typename?: 'Mutation', deleteAllApiKeys: boolean };

export type AuthenticateMutationVariables = Exact<{
  password: Scalars['String'];
}>;


export type AuthenticateMutation = { __typename?: 'Mutation', authenticate: boolean };

export type VerifiedContractQueryVariables = Exact<{
  hash: Scalars['String'];
}>;


export type VerifiedContractQuery = { __typename?: 'Query', verifiedContract?: { __typename?: 'Contract', id: string, name: string, compilerVersion: string, evmVersion: string, abi: any, optimizer: { __typename?: 'Optimizer', enabled: boolean, runs: number }, sources: Array<{ __typename?: 'Source', content: string, name: string }> } | null };

export type VerifyContractMutationVariables = Exact<{
  input: VerifyContractInput;
}>;


export type VerifyContractMutation = { __typename?: 'Mutation', verifyContract: boolean };

export type OverviewQueryVariables = Exact<{ [key: string]: never; }>;


export type OverviewQuery = { __typename?: 'Query', overview: { __typename?: 'OverviewData', priceUsd: number, priceBtc: number, priceChangePercentage24h: number, marketCapUsd: number } };

export type TokenMappingsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type TokenMappingsQuery = { __typename?: 'Query', tokenMappings: { __typename?: 'TokenMapping', data: Array<{ __typename?: 'TokenMappingData', id: string, l1Address: string, l2Address: string, decimalsL1: number, decimalsL2: number, symbol: string }>, paginationContext: { __typename?: 'PaginationContext', limit: number, offset: number, totalElements: number } } };

export type TransactionReceiptQueryVariables = Exact<{
  txnHash: Scalars['String'];
}>;


export type TransactionReceiptQuery = { __typename?: 'Query', transactionReceipt: { __typename?: 'Transaction', status: boolean, transactionHash: string, transactionIndex: number, from: string, to: string, blockNumber: number, blockHash: string, contractAddress: string, cumulativeGasUsed: number, gasUsed: number, effectiveGasPrice: number, logsBloom: string, events: any, logs: Array<{ __typename?: 'Log', address: string, name: string, topics: Array<string>, data: string }> } };


export const ApiKeysDocument = gql`
    query ApiKeys {
  apiKeys {
    id
    address
    name
    key
    createdAt
  }
}
    `;

/**
 * __useApiKeysQuery__
 *
 * To run a query within a React component, call `useApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiKeysQuery(baseOptions?: Apollo.QueryHookOptions<ApiKeysQuery, ApiKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiKeysQuery, ApiKeysQueryVariables>(ApiKeysDocument, options);
      }
export function useApiKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiKeysQuery, ApiKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiKeysQuery, ApiKeysQueryVariables>(ApiKeysDocument, options);
        }
export type ApiKeysQueryHookResult = ReturnType<typeof useApiKeysQuery>;
export type ApiKeysLazyQueryHookResult = ReturnType<typeof useApiKeysLazyQuery>;
export type ApiKeysQueryResult = Apollo.QueryResult<ApiKeysQuery, ApiKeysQueryVariables>;
export const CreateApiKeyDocument = gql`
    mutation CreateApiKey($input: CreateApiKeyInput!) {
  createApiKey(input: $input) {
    id
    address
    name
    key
    createdAt
  }
}
    `;
export type CreateApiKeyMutationFn = Apollo.MutationFunction<CreateApiKeyMutation, CreateApiKeyMutationVariables>;

/**
 * __useCreateApiKeyMutation__
 *
 * To run a mutation, you first call `useCreateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiKeyMutation, { data, loading, error }] = useCreateApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApiKeyMutation, CreateApiKeyMutationVariables>(CreateApiKeyDocument, options);
      }
export type CreateApiKeyMutationHookResult = ReturnType<typeof useCreateApiKeyMutation>;
export type CreateApiKeyMutationResult = Apollo.MutationResult<CreateApiKeyMutation>;
export type CreateApiKeyMutationOptions = Apollo.BaseMutationOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>;
export const UpdateApiKeyDocument = gql`
    mutation UpdateApiKey($input: UpdateApiKeyInput!) {
  updateApiKey(input: $input) {
    id
    address
    name
    key
    createdAt
  }
}
    `;
export type UpdateApiKeyMutationFn = Apollo.MutationFunction<UpdateApiKeyMutation, UpdateApiKeyMutationVariables>;

/**
 * __useUpdateApiKeyMutation__
 *
 * To run a mutation, you first call `useUpdateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApiKeyMutation, { data, loading, error }] = useUpdateApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApiKeyMutation, UpdateApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApiKeyMutation, UpdateApiKeyMutationVariables>(UpdateApiKeyDocument, options);
      }
export type UpdateApiKeyMutationHookResult = ReturnType<typeof useUpdateApiKeyMutation>;
export type UpdateApiKeyMutationResult = Apollo.MutationResult<UpdateApiKeyMutation>;
export type UpdateApiKeyMutationOptions = Apollo.BaseMutationOptions<UpdateApiKeyMutation, UpdateApiKeyMutationVariables>;
export const DeleteApiKeyDocument = gql`
    mutation DeleteApiKey($input: DeleteApiKeyInput!) {
  deleteApiKey(input: $input)
}
    `;
export type DeleteApiKeyMutationFn = Apollo.MutationFunction<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>;

/**
 * __useDeleteApiKeyMutation__
 *
 * To run a mutation, you first call `useDeleteApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiKeyMutation, { data, loading, error }] = useDeleteApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>(DeleteApiKeyDocument, options);
      }
export type DeleteApiKeyMutationHookResult = ReturnType<typeof useDeleteApiKeyMutation>;
export type DeleteApiKeyMutationResult = Apollo.MutationResult<DeleteApiKeyMutation>;
export type DeleteApiKeyMutationOptions = Apollo.BaseMutationOptions<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>;
export const DeleteAllApiKeysDocument = gql`
    mutation DeleteAllApiKeys {
  deleteAllApiKeys
}
    `;
export type DeleteAllApiKeysMutationFn = Apollo.MutationFunction<DeleteAllApiKeysMutation, DeleteAllApiKeysMutationVariables>;

/**
 * __useDeleteAllApiKeysMutation__
 *
 * To run a mutation, you first call `useDeleteAllApiKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllApiKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllApiKeysMutation, { data, loading, error }] = useDeleteAllApiKeysMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAllApiKeysMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAllApiKeysMutation, DeleteAllApiKeysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAllApiKeysMutation, DeleteAllApiKeysMutationVariables>(DeleteAllApiKeysDocument, options);
      }
export type DeleteAllApiKeysMutationHookResult = ReturnType<typeof useDeleteAllApiKeysMutation>;
export type DeleteAllApiKeysMutationResult = Apollo.MutationResult<DeleteAllApiKeysMutation>;
export type DeleteAllApiKeysMutationOptions = Apollo.BaseMutationOptions<DeleteAllApiKeysMutation, DeleteAllApiKeysMutationVariables>;
export const AuthenticateDocument = gql`
    mutation Authenticate($password: String!) {
  authenticate(password: $password)
}
    `;
export type AuthenticateMutationFn = Apollo.MutationFunction<AuthenticateMutation, AuthenticateMutationVariables>;

/**
 * __useAuthenticateMutation__
 *
 * To run a mutation, you first call `useAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateMutation, { data, loading, error }] = useAuthenticateMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthenticateMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateMutation, AuthenticateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateMutation, AuthenticateMutationVariables>(AuthenticateDocument, options);
      }
export type AuthenticateMutationHookResult = ReturnType<typeof useAuthenticateMutation>;
export type AuthenticateMutationResult = Apollo.MutationResult<AuthenticateMutation>;
export type AuthenticateMutationOptions = Apollo.BaseMutationOptions<AuthenticateMutation, AuthenticateMutationVariables>;
export const VerifiedContractDocument = gql`
    query VerifiedContract($hash: String!) {
  verifiedContract(hash: $hash) {
    id
    name
    compilerVersion
    evmVersion
    optimizer {
      enabled
      runs
    }
    sources {
      content
      name
    }
    abi
  }
}
    `;

/**
 * __useVerifiedContractQuery__
 *
 * To run a query within a React component, call `useVerifiedContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifiedContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifiedContractQuery({
 *   variables: {
 *      hash: // value for 'hash'
 *   },
 * });
 */
export function useVerifiedContractQuery(baseOptions: Apollo.QueryHookOptions<VerifiedContractQuery, VerifiedContractQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifiedContractQuery, VerifiedContractQueryVariables>(VerifiedContractDocument, options);
      }
export function useVerifiedContractLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifiedContractQuery, VerifiedContractQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifiedContractQuery, VerifiedContractQueryVariables>(VerifiedContractDocument, options);
        }
export type VerifiedContractQueryHookResult = ReturnType<typeof useVerifiedContractQuery>;
export type VerifiedContractLazyQueryHookResult = ReturnType<typeof useVerifiedContractLazyQuery>;
export type VerifiedContractQueryResult = Apollo.QueryResult<VerifiedContractQuery, VerifiedContractQueryVariables>;
export const VerifyContractDocument = gql`
    mutation VerifyContract($input: VerifyContractInput!) {
  verifyContract(input: $input)
}
    `;
export type VerifyContractMutationFn = Apollo.MutationFunction<VerifyContractMutation, VerifyContractMutationVariables>;

/**
 * __useVerifyContractMutation__
 *
 * To run a mutation, you first call `useVerifyContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyContractMutation, { data, loading, error }] = useVerifyContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyContractMutation(baseOptions?: Apollo.MutationHookOptions<VerifyContractMutation, VerifyContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyContractMutation, VerifyContractMutationVariables>(VerifyContractDocument, options);
      }
export type VerifyContractMutationHookResult = ReturnType<typeof useVerifyContractMutation>;
export type VerifyContractMutationResult = Apollo.MutationResult<VerifyContractMutation>;
export type VerifyContractMutationOptions = Apollo.BaseMutationOptions<VerifyContractMutation, VerifyContractMutationVariables>;
export const OverviewDocument = gql`
    query Overview {
  overview {
    priceUsd
    priceBtc
    priceChangePercentage24h
    marketCapUsd
  }
}
    `;

/**
 * __useOverviewQuery__
 *
 * To run a query within a React component, call `useOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useOverviewQuery(baseOptions?: Apollo.QueryHookOptions<OverviewQuery, OverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OverviewQuery, OverviewQueryVariables>(OverviewDocument, options);
      }
export function useOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OverviewQuery, OverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OverviewQuery, OverviewQueryVariables>(OverviewDocument, options);
        }
export type OverviewQueryHookResult = ReturnType<typeof useOverviewQuery>;
export type OverviewLazyQueryHookResult = ReturnType<typeof useOverviewLazyQuery>;
export type OverviewQueryResult = Apollo.QueryResult<OverviewQuery, OverviewQueryVariables>;
export const TokenMappingsDocument = gql`
    query TokenMappings($limit: Int!, $offset: Int!) {
  tokenMappings(limit: $limit, offset: $offset) {
    data {
      id
      l1Address
      l2Address
      decimalsL1
      decimalsL2
      symbol
    }
    paginationContext {
      limit
      offset
      totalElements
    }
  }
}
    `;

/**
 * __useTokenMappingsQuery__
 *
 * To run a query within a React component, call `useTokenMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenMappingsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useTokenMappingsQuery(baseOptions: Apollo.QueryHookOptions<TokenMappingsQuery, TokenMappingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenMappingsQuery, TokenMappingsQueryVariables>(TokenMappingsDocument, options);
      }
export function useTokenMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenMappingsQuery, TokenMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenMappingsQuery, TokenMappingsQueryVariables>(TokenMappingsDocument, options);
        }
export type TokenMappingsQueryHookResult = ReturnType<typeof useTokenMappingsQuery>;
export type TokenMappingsLazyQueryHookResult = ReturnType<typeof useTokenMappingsLazyQuery>;
export type TokenMappingsQueryResult = Apollo.QueryResult<TokenMappingsQuery, TokenMappingsQueryVariables>;
export const TransactionReceiptDocument = gql`
    query TransactionReceipt($txnHash: String!) {
  transactionReceipt(txnHash: $txnHash) {
    status
    transactionHash
    transactionIndex
    from
    to
    blockNumber
    blockHash
    contractAddress
    cumulativeGasUsed
    gasUsed
    effectiveGasPrice
    logsBloom
    events
    logs {
      address
      name
      topics
      data
    }
  }
}
    `;

/**
 * __useTransactionReceiptQuery__
 *
 * To run a query within a React component, call `useTransactionReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionReceiptQuery({
 *   variables: {
 *      txnHash: // value for 'txnHash'
 *   },
 * });
 */
export function useTransactionReceiptQuery(baseOptions: Apollo.QueryHookOptions<TransactionReceiptQuery, TransactionReceiptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionReceiptQuery, TransactionReceiptQueryVariables>(TransactionReceiptDocument, options);
      }
export function useTransactionReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionReceiptQuery, TransactionReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionReceiptQuery, TransactionReceiptQueryVariables>(TransactionReceiptDocument, options);
        }
export type TransactionReceiptQueryHookResult = ReturnType<typeof useTransactionReceiptQuery>;
export type TransactionReceiptLazyQueryHookResult = ReturnType<typeof useTransactionReceiptLazyQuery>;
export type TransactionReceiptQueryResult = Apollo.QueryResult<TransactionReceiptQuery, TransactionReceiptQueryVariables>;