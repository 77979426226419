import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys)

const baseStyle = definePartsStyle({
  container: {
    backgroundColor: 'white',
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.08)',
    borderRadius: 6,
  },
})

export const Card = defineMultiStyleConfig({ baseStyle })
