import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const xl = defineStyle({ fontWeight: 700, fontSize: '24px' })
const l = defineStyle({ fontWeight: 700, fontSize: '20px' })
const m = defineStyle({ fontWeight: 700, fontSize: '18px' })
const s = defineStyle({ fontWeight: 700, fontSize: '16px' })
const xs = defineStyle({ fontWeight: 700, fontSize: '14px' })
const subtitle = defineStyle({ fontWeight: 400, fontSize: '10px' })

export const Heading = defineStyleConfig({
  defaultProps: { variant: 'l' },
  variants: {
    xl,
    l,
    m,
    s,
    xs,
    subtitle,
  },
})
