import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const large = defineStyle({ fontWeight: 400, fontSize: '16px' })
const largeBold = defineStyle({ fontWeight: 700, fontSize: '16px' })

const regular = defineStyle({ fontWeight: 400, fontSize: '14px' })
const regularBold = defineStyle({ fontWeight: 700, fontSize: '14px' })

const small = defineStyle({ fontWeight: 400, fontSize: '12px' })
const smallBold = defineStyle({ fontWeight: 700, fontSize: '12px' })

const tiny = defineStyle({ fontWeight: 400, fontSize: '10px' })
const tinyBold = defineStyle({ fontWeight: 700, fontSize: '10px' })

export const Text = defineStyleConfig({
  defaultProps: { variant: 'regular' },
  variants: {
    large,
    largeBold,
    regular,
    regularBold,
    small,
    smallBold,
    tiny,
    tinyBold,
  },
})
