import { tableAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tableAnatomy.keys
)

const baseStyle = definePartsStyle({
  thead: {
    th: {
      borderColor: 'lightGray',
      fontSize: '14px',
      fontWeight: 700,
      color: 'darkGray',
      textTransform: 'none',
      letterSpacing: 'normal',
      py: 4,
    },
  },
  tbody: {
    td: {
      borderColor: 'lightGray',
      fontWeight: 400,
      fontSize: '14px',
      py: 3,
    },
  },
})

export const Table = defineMultiStyleConfig({ baseStyle })
