import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys)

const baseStyle = definePartsStyle({
  list: {
    py: 0,
    borderRadius: 6,
    boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.15)',
    border: 'none',
  },
  item: {
    py: 3,
    px: 6,
    fontSize: 'xs',
    _hover: {
      bg: 'lightBlue',
    },
    _focus: {
      bg: 'lightBlue',
    },
    _first: {
      borderRadius: '6px 6px 0 0',
    },
    _last: {
      borderRadius: '0 0 6px 6px',
      borderBottom: 'none',
    },
    borderBottom: '1px solid',
    borderBottomColor: 'lightGray',
    lineHeight: '0.875rem',
  },
})

export const Menu = defineMultiStyleConfig({ baseStyle })
