import { GraphQLError } from 'graphql'

export enum ERROR_KEYS {
  UserAlreadyExists = 'UserAlreadyExists',
  SomethingWentWrong = 'SomethingWentWrong',
  InvalidCredentials = 'InvalidCredentials',
  Unauthorized = 'Unauthorized',
  ApiKeyNotFound = 'ApiKeyNotFound',
  ApiKeysLimitExceeded = 'ApiKeysLimitExceeded',
}

export enum ERROR_TYPES {
  Forbidden = 'Forbidden',
  NotFound = 'NotFound',
  Conflict = 'Conflict',
  InternalServerError = 'InternalServerError',
  Validation = 'Validation',
  Unauthorized = 'Unauthorized',
  BadRequest = 'BadRequest',
}

export const ErrorMessages = {
  [ERROR_KEYS.UserAlreadyExists]:
    'Sorry, that email address is already associated with an account.',
  [ERROR_KEYS.SomethingWentWrong]: 'Something went wrong.',
  [ERROR_KEYS.InvalidCredentials]: 'Invalid credentials.',
  [ERROR_KEYS.Unauthorized]: 'User not authorized.',
  [ERROR_KEYS.ApiKeyNotFound]: 'API key not found.',
  [ERROR_KEYS.ApiKeysLimitExceeded]: 'The maximum number of API keys has been exceeded.',
  web3TransactionReceipt: 'Failed to fetch transaction for provided hash.',
  contractVerification: 'Unable to verify contract.',
  contractVerificationInput:
    'Verification failed. Please, check if you have uploaded the correct JSON input with the corresponding contract bytecode.',
  contractAlreadyVerified: 'Verification failed. Smart contract is already verified.',
  contractVerificationVersionInvalid:
    'Verification failed. Provided compiler version is not valid.',
  overview: 'Failed to fetch overview data.',
  memoryOutOfBounds: 'Memory out of bounds. Uploaded JSON should not exceed 1.2MB.',
  invalidJsonFormat: 'Invalid JSON format.',
}

export class ValidationError extends GraphQLError {
  constructor(message: string) {
    super(message, { extensions: { code: ERROR_TYPES.Validation } })
  }
}

export class AuthError extends GraphQLError {
  constructor(message: string) {
    super(message, {
      extensions: {
        code: ERROR_TYPES.Unauthorized,
      },
    })
  }
}

export class ForbiddenError extends GraphQLError {
  constructor(message: string) {
    super(message, { extensions: { code: ERROR_TYPES.Forbidden } })
  }
}

export class NotFoundError extends GraphQLError {
  constructor(message: string) {
    super(message, { extensions: { code: ERROR_TYPES.NotFound } })
  }
}

export class ConflictError extends GraphQLError {
  constructor(message: string) {
    super(message, { extensions: { code: ERROR_TYPES.Conflict } })
  }
}

export class InternalServerError extends GraphQLError {
  constructor(message: string) {
    super(message, { extensions: { code: ERROR_TYPES.InternalServerError } })
  }
}

export class BadRequestError extends GraphQLError {
  constructor(message: string) {
    super(message, { extensions: { code: ERROR_TYPES.BadRequest } })
  }
}

export class TransactionReceiptNotFoundError extends GraphQLError {
  constructor(message?: string) {
    super(message || ErrorMessages.web3TransactionReceipt)
  }
}

export class ContractVerificationError extends GraphQLError {
  constructor(message?: string, data = {}) {
    super(message || ErrorMessages.contractVerification, {
      extensions: { data },
    })
  }
}

export class OverviewError extends GraphQLError {
  constructor(message?: string) {
    super(message || ErrorMessages.overview)
  }
}
