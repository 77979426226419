import React from 'react'
import { Box, Button, Center, Input, Spinner } from '@chakra-ui/react'
import { useAuthenticateMutation } from 'apollo/generated/graphqlClient'

const AUTH_KEY = 'password'

type AuthContextType = {
  handleAuthenticate: ({ password }: { password: string }) => void
  isAuthenticated: boolean
  isLoading: boolean
}

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false)

  const [authenticate, { loading }] = useAuthenticateMutation()

  const handleAuthenticate = React.useCallback(
    async ({ password }: { password: string }) => {
      const response = await authenticate({
        variables: { password },
      })

      const localStoragePassword = localStorage.getItem(AUTH_KEY)

      if (localStoragePassword !== password) {
        localStorage.setItem(AUTH_KEY, password)
      }

      setIsAuthenticated(response?.data?.authenticate ?? false)
    },
    [authenticate]
  )

  React.useEffect(() => {
    const auth = async () => {
      const localStoragePassword = localStorage.getItem(AUTH_KEY)

      if (localStoragePassword) {
        await handleAuthenticate({ password: localStoragePassword })
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    auth()
  }, [handleAuthenticate])

  return { isAuthenticated, isLoading: loading, handleAuthenticate }
}

const AuthContext = React.createContext<AuthContextType | null>(null)

const useAuthContext = () => React.useContext(AuthContext)

const AuthProvider = ({ children }) => {
  const auth = useAuth()

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const [password, setPassword] = React.useState('')
  const { isAuthenticated, isLoading, handleAuthenticate } = useAuthContext()

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    )
  }

  return !isAuthenticated ? (
    <Center h="100vh">
      <Box p={6} border="1px solid" borderColor="primary" borderRadius={4}>
        <Input
          mb={4}
          color="darkGray"
          borderRadius={6}
          value={password}
          placeholder="Enter a password"
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleAuthenticate({ password })
            }
          }}
          onChange={(event) => {
            setPassword(event.target.value)
          }}
        />
        <Button
          onClick={() => {
            handleAuthenticate({ password })
          }}
        >
          Submit
        </Button>
      </Box>
    </Center>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  )
}

export { AuthProvider, ProtectedRoute, useAuthContext }
