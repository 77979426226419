import { connectorsForWallets } from '@rainbow-me/rainbowkit'
import { metaMaskWallet } from '@rainbow-me/rainbowkit/wallets'
import { configureChains, createClient } from 'wagmi'
import { sepolia } from 'wagmi/chains'
import { publicProvider } from 'wagmi/providers/public'

export function initializeRainbowKit() {
  const { chains, provider } = configureChains([sepolia], [publicProvider()])

  const connectors = connectorsForWallets([
    {
      groupName: 'Available Wallets',
      wallets: [metaMaskWallet({ chains })],
    },
  ])

  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
  })

  return { wagmiClient, chains }
}
