import { ApolloProvider } from '@apollo/client'
import { ChakraProvider } from '@chakra-ui/react'
import { RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { RainbowKitSiweNextAuthProvider } from '@rainbow-me/rainbowkit-siwe-next-auth'
import { AuthProvider, ProtectedRoute } from 'context/AuthContext'
import { useApollo } from 'hooks/useApollo'
import { theme } from 'lib/chakra/theme'
import { initializeRainbowKit } from 'lib/rainbowkit'
import type { AppProps } from 'next/app'
import { Ubuntu } from 'next/font/google'
import { SessionProvider } from 'next-auth/react'
import { BaseAppProps, NextPageWithLayout } from 'types/next'
import { WagmiConfig } from 'wagmi'
import { ReactQueryClientProvider } from 'components/ReactQueryClientProvider'
import '@rainbow-me/rainbowkit/styles.css'

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  import('../mocks')
}

type AppPropsWithLayout = AppProps<BaseAppProps> & {
  Component: NextPageWithLayout<BaseAppProps>
}

const { wagmiClient, chains } = initializeRainbowKit()

const ubuntu = Ubuntu({ weight: ['400', '700'], subsets: ['latin'] })

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const apolloClient = useApollo(pageProps.initialApolloState)
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <ChakraProvider theme={theme}>
      <ApolloProvider client={apolloClient}>
        <WagmiConfig client={wagmiClient}>
          <SessionProvider refetchInterval={0} session={pageProps.session}>
            <RainbowKitSiweNextAuthProvider>
              <RainbowKitProvider chains={chains}>
                <ReactQueryClientProvider>
                  <main className={`${ubuntu.className}`}>
                    <AuthProvider>
                      <ProtectedRoute>{getLayout(<Component {...pageProps} />)}</ProtectedRoute>
                    </AuthProvider>
                  </main>
                </ReactQueryClientProvider>
              </RainbowKitProvider>
            </RainbowKitSiweNextAuthProvider>
          </SessionProvider>
        </WagmiConfig>
      </ApolloProvider>
    </ChakraProvider>
  )
}

export default App
