import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

// eslint-disable-next-line @typescript-eslint/unbound-method
const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(tabsAnatomy.keys)

const variants = {
  primary: definePartsStyle({
    tab: {
      py: 3,
      px: 4,
      fontSize: '14px',
      fontWeight: '700',
      _selected: {
        bgColor: 'lightBlue',
        color: 'secondary',
      },
    },
  }),
  secondary: definePartsStyle({
    tab: {
      p: 2,
      fontSize: '14px',
      bgColor: 'lightGray',
      _selected: {
        bgColor: 'black',
        color: 'white',
      },
    },
  }),
}

const baseStyle = definePartsStyle({
  tab: {
    _notFirst: { ml: 2 },
    color: 'darkGray',
    borderRadius: 6,
  },
  tabpanel: {
    px: 0,
    py: 0,
  },
})

export const Tabs = defineMultiStyleConfig({
  defaultProps: { variant: 'primary' },
  variants,
  baseStyle,
})
