import { extendTheme } from '@chakra-ui/react'
import * as components from './components'

export const BORDER_RADIUS_PILL = '9999px'
export const PAGE_OFFSET_X = 10

export const COLORS = {
  primary: '#4BBCFC',
  secondary: '#052892',
  background: '#F8FAFD',
  backgroundAlt: '#E1E7F0',
  lightBlue: '#E4EBFF',
  titanGray: '#8696B2',
  linkColor: '#0631B2',
  darkGray: '#666666',
  mediumGray: '#888888',
  gray: '#D6D8D9',
  lightGray: '#F1F3F4',
  red: '#CA2323',
  lightRed: 'rgba(224, 22, 22, 0.2)',
  orange: '#C17A0F',
  lightOrange: 'rgba(224, 144, 22, 0.2)',
  green: '#21A66C',
  lightGreen: 'rgba(61, 220, 151, 0.2)',
}

export const theme = extendTheme({
  components: { ...components },
  styles: {
    global: {
      'html, body': {
        minHeight: '100vh',
        background: 'background',
      },
    },
  },
  colors: COLORS,
})
