import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const sizes = defineStyle({
  regular: { fontSize: '14px', py: 3, px: 4 },
  small: { fontSize: '12px', py: 2, px: 3 },
})

const baseStyle = defineStyle({ fontWeight: 400, borderRadius: 6 })

const variants = defineStyle({
  primary: {
    bgColor: 'primary',
    color: 'white',
    fontWeight: 700,
    _hover: {
      bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #4BBCFC',
      _disabled: {
        bgColor: 'primary',
      },
    },
  },
  secondary: {
    bgColor: 'lightBlue',
    color: 'secondary',
    _hover: {
      bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #E4EBFF',
      _disabled: {
        bgColor: 'lightBlue',
      },
    },
  },
  tertiary: {
    bgColor: 'gray',
    _hover: {
      bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #D6D8D9',
    },
  },
  success: { bgColor: 'lightGreen' },
})

export const Button = defineStyleConfig({
  defaultProps: { variant: 'primary', size: 'regular' },
  variants,
  baseStyle,
  sizes,
})
