import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys
)

const sizes = {
  xl: definePartsStyle({
    field: { fontSize: '14px', py: 5, px: 4 },
  }),
}

const variants = {
  search: definePartsStyle({
    field: {
      _placeholder: { color: 'mediumGray' },
      bg: 'white',
    },
  }),
}

const baseStyle = definePartsStyle({ field: { borderRadius: 6 } })

export const Input = defineMultiStyleConfig({ baseStyle, variants, sizes })
